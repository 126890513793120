// navbar mobile execution
.navbar-backdrop {
  display: none;
  @media (max-width: 1199px) {
    display: block;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    background-color: #000;
    opacity: 0;
    position: fixed;
    z-index: 999;
    top: 0;
    left: -100%;
    transition: left 0s 0.3s, opacity 0.3s;
    &.is-show {
      left: 0;
      opacity: 0.5;
      transition: left 0s, opacity 0.3s;
    }
  }
}
.navbar {
  padding: 0;
}
.navbar-mobile {
  display: block;
  position: fixed;
  z-index: $zindex-fixed;
  top: 0;
  left: -100%;
  width: 300px;
  height: 100vh;
  overflow-y: auto;
  background-color: $white;
  transition: 0.3s;
  align-items: flex-start;
  &.is-show {
    left: 0;
  }
  .dropdown-menu {
    padding-left: 15px;
  }
}

.navbar-header {
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  background-color: $gray-800;
  margin-bottom: 20px;
  box-shadow: 0 1px 10px rgba(#000, 0.1);
  color: rgba(#fff, 0.8);
  text-shadow: 0 1px 2px rgba(#000, 0.6);
}

.navbar-close {
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border: 0;
  background: 0;
  color: rgba(#fff, 0.8);
  position: absolute;
  top: 0;
  right: 0;
}

@each $key, $val in $grid-breakpoints {
  @media (min-width: #{$val}) {
    .navbar-mobile.navbar-expand-#{$key} {
      display: flex;
      flex-grow: 1;
      align-items: center;
      position: static;
      background: none;
      z-index: 0;
      top: auto;
      left: auto;
      width: auto;
      height: auto;
      overflow: visible;
      transition: unset;
      .dropdown-menu {
        padding-left: 0;
      }
      .navbar-header {
        display: none;
      }
    }
  }
}
