.banner-slider {
  &__item {
    width: 100%;
    //height: 100vh;
    position: relative;
    z-index: 1;
    //padding-top: 210px;
  }

  &__bg {
    display: block;
    width: 100%;
    //@include img-cover;
    //position: absolute;
    //top: 0;
    //left: 0;
    //z-index: -1;
  }

  &__title {
    font-size: 48px;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    text-align: center;
    padding-bottom: 3px;
    transform: translateY(-200px);
    transition: 1s 0.5s;
    opacity: 0;

    &::before {
      content: '';
      display: block;
      width: 50px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      border-bottom: 1px solid $primary;
    }
  }

  &__subtitle {
    text-align: center;
    font-size: 16px;
    line-height: 36px;
    font-weight: 700;
    transform: translateX(-200px);
    transition: 1s 1s ease-in-out;
    opacity: 0;
  }

  &__text {
    font-family: $aristore;
    text-align: center;
    font-size: 16px;
    line-height: 36px;
    margin-bottom: 16px;
    transform: translateX(200px);
    transition: 1s 1.5s ease-in-out;
    opacity: 0;
  }

  &__btn {
    transform: translateY(200px);
    transition: 1s 2s ease-in-out;
    opacity: 0;

    &:hover {
      color: $white;
    }

    .button {
      font-size: 16px;
      line-height: 38px;
      padding: 0 20px;
    }
  }

  & .swiper-slide-active &__title {
    transform: translateY(0);
    opacity: 1;
  }

  & .swiper-slide-active &__subtitle {
    transform: translateX(0);
    opacity: 1;
  }

  & .swiper-slide-active &__text {
    transform: translateX(0);
    opacity: 1;
  }

  & .swiper-slide-active &__btn {
    transform: translateY(0);
    opacity: 1;
  }

  @media (max-width: 991px) {
    &__title {
      font-size: 30px;
      margin-bottom: 8px;
    }

    &__subtitle {
      font-size: 14px;
      line-height: 21px;
    }
    &__text {
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.counting-section {
  background: url('../images/home-news-bg.png') center/cover no-repeat;
}

.counting {
  display: flex;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    display: block;
    width: 80%;
    height: 50px;
    position: absolute;
    z-index: -1;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    background-image: $bg-gradient;
  }

  &__item {
    flex: 0 0 20%;
    max-width: 20%;
    height: 500px;
    position: relative;
  }

  &__circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    @include box-shadow;

    &::before {
      content: '';
      display: block;
      background-color: $white;
      border-radius: 50%;
      background: $bg-gradient;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      transition: 0.5s;
    }

    &::after {
      content: '';
      display: block;
      background-color: $white;
      border-radius: 50%;
      position: absolute;
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
      z-index: -1;
    }

    &:hover::before {
      transform: rotate(180deg);
    }
  }

  &__circle-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $bg-gradient;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;

    &::after {
      content: '';
      display: block;
      background-color: $white;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      z-index: -1;
    }
  }

  &__icon {
    width: 113px;
    height: 76px;
    object-fit: contain;
    object-position: 50% 50%;
    display: block;
    margin: 0 auto;
  }

  &__circle:hover &__icon {
    animation: heartBeat 1.3s ease-in-out;
  }

  &__text {
    display: flex;
    height: 70px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 18px;
    color: #0114ff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    &::before {
      content: '';
      display: block;
      width: 8px;
      height: 75px;
      background-image: linear-gradient(to right, #0785ff, #0440ff);
      position: absolute;
      bottom: calc(100% + 5px);
      left: calc(50% - 4px);
    }

    &::after {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-image: linear-gradient(to right, #09a9ff, #0230ff);
      position: absolute;
      bottom: calc(100% + 4px);
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__item:nth-child(even) &__text {
    bottom: auto;
    top: 0;

    &::before {
      bottom: auto;
      top: calc(100% + 5px);
    }

    &::after {
      bottom: auto;
      top: calc(100% + 4px);
    }
  }

  @media (max-width: 1199px) {
    flex-direction: column;

    &::after {
      top: 10%;
      left: 75px;
      height: 80%;
      width: 30px;
      transform: translateX(-50%);
    }

    &__item {
      display: flex;
      align-items: center;
      flex: auto;
      height: auto;
      padding: 15px 0;
      max-width: unset;
    }

    &__circle {
      width: 150px;
      height: 150px;
      position: relative;
      top: auto;
      left: auto;
      transform: unset;

      &::after {
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
      }
    }

    &__circle-inner {
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
    }

    &__icon {
      width: 130px;
      height: 50px;
    }

    &__text {
      flex: 1;
      position: relative;
      bottom: auto;
      left: auto;
      right: auto;
      order: 1;
      padding-left: 50px;
      padding-bottom: 2px;
      text-align: left;
      align-items: flex-start;

      &::before {
        width: 40px;
        height: 8px;
        background: #0ac0ff;
        bottom: auto !important;
        top: 50% !important;
        left: 0;
        transform: translateY(-50%);
      }

      &::after {
        left: 25px;
        top: 50% !important;
        bottom: auto !important;
        transform: translateY(-50%);
      }
    }
  }
}

.home-sync {
  overflow: hidden;

  &__title {
    position: absolute;
    z-index: 10;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1200px;
    padding: 0 15px;
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 16px;
      top: 16px;
    }
  }
}

.sync1 {
  &__frame {
    display: block;
    background-color: $white;
    width: 100%;
    position: relative;
    z-index: 1;
    cursor: pointer;
  }

  &__placeholder {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__icon {
    max-height: 20%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__frame.active &__placeholder {
    opacity: 0;
  }

  &__frame.active &__icon {
    display: none;
  }

  .swiper-slide {
    background-color: $white;
    height: auto;
  }
}

.sync2 {
  position: absolute;
  z-index: 10;
  bottom: 30px;
  left: 0;
  right: 0;

  &__inner {
    padding: 0 60px;
    position: relative;
  }

  &__prev,
  &__next {
    display: flex;
    width: 30px;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
    color: $white;
    font-size: 30px;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: 30px;
  }

  &__item {
    @include iwrap(45%);
  }

  .swiper-slide {
    transform: scale(0.8);
    transition: 0.3s;
    @include box-shadow;

    &-prev {
      transform: scale(0.9) translateX(-11px);
    }

    &-next {
      transform: scale(0.9) translateX(11px);
    }

    &-active {
      transform: scale(1);
    }
  }

  @media (max-width: 575px) {
    bottom: 5px;
  }
}

.home-gallery {
  background: url('../images/home-gallery-bg.jpg') center/cover no-repeat;
  overflow: hidden;

  &__title {
    padding: 50px 0;
    margin-bottom: 0;
  }
}

.gallery-slider {
  position: relative;

  &__prev,
  &__next {
    padding: 10px 5px;
    position: absolute;
    top: 50%;
    left: -40px;
    transform: translateY(-50%);
    z-index: 10;
    font-size: 40px;
    color: #666;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: -40px;
  }

  &__iwrap {
    @include iwrap(75%);
    @include shadow-md;
  }

  .swiper-slide {
    padding: 10px;
    position: relative;
    z-index: 0;
    transform: scale(0.7);
    transition: 0.3s;

    &-prev,
    &-next {
      z-index: 2;
      transform: scale(0.86);
    }

    &-next + .swiper-slide {
      z-index: 1;
    }

    &-active {
      transform: scale(1);
      z-index: 3;
    }
  }
}

.home-news {
  background: url('../images/home-news-bg.png') center/cover no-repeat;
  padding: 0 0 40px;
  overflow: hidden;

  &__title {
    padding: 70px 0 45px;
    margin-bottom: 0;
  }
}

.news-slider {
  margin: 0 -30px;
  position: relative;

  &__prev,
  &__next {
    padding: 10px 5px;
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
    z-index: 10;
    font-size: 40px;
    color: #666;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: -20px;
  }

  .swiper-slide {
    height: auto;
    align-self: stretch;
    padding: 30px;
  }

  @media (max-width: 575px) {
    &__prev,
    &__next {
      background-color: rgba(#fff, 0.8);
      font-size: 34px;
      @include shadow;
    }

    &__prev {
      left: 15px;
    }

    &__next {
      right: 15px;
    }
  }
}
