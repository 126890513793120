.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    &__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
    &__inner {
        display: flex;
        align-items: center;
        padding: 18px 30px 14px;
        background-color: rgba(#000, 0.15);
        border-radius: 0 0 15px 15px;
        position: relative;
        transition: 0.3s;
    }
    &__logo {
        display: inline-flex;
        img {
            height: 50px;
        }
    }
    &.active &__inner {
        background-color: rgba(#000, 0.8);
    }
    @media (min-width: 1200px) {
        &__logo {
            img {
                height: 110px;
                transition: 0.3s;
            }
        }
        &.active &__logo {
            img {
                height: 50px;
            }
        }
    }
}

@media (max-width: 1199px) {
    body:not(#home-page) .header__inner {
        background-color: rgba(#000, 0.8);
    }
}

.lang {
    padding-bottom: 20px;
    border-bottom: 1px solid $border-color;
    &__title {
        text-align: center;
        margin-bottom: 5px;
    }
    &__wrapper {
        display: flex;
        justify-content: center;
    }
    &__item {
        display: inline-flex;
        margin: 0 4px;
        border: 1px solid $border-color;
        img {
            height: 25px;
        }
    }
    @media (min-width: 1200px) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 100%;
        margin-left: 10px;
        min-width: 33px;
        border-bottom: 0;
        padding-bottom: 0;
        &__title {
            display: none;
        }
        &__wrapper {
            flex-direction: column;
        }
        &__item {
            margin: 4px 0;
            border: 0;
        }
    }
    @media (min-width: 1400px) {
        min-width: 90px;
        margin-left: 16px;
        &__title {
            display: block;
            text-align: center;
            color: $white;
            text-transform: uppercase;
            white-space: nowrap;
            text-shadow: 1px 1px #333, -1px 1px #333, 1px -1px #333, -1px -1px #333;
        }
        &__wrapper {
            flex-direction: row;
        }
        &__item {
            margin: 0 4px;
        }
    }
}

.navbar-mobile-btn {
    border: 1px solid $white;
    padding: 0;
    width: 40px;
    height: 35px;
    border-radius: 4px;
    margin-left: auto;
    background: 0;
    color: $white;
}

.menu-root {
    margin-left: auto;
    @media (min-width: 1200px) {
        margin-left: auto;
        &>.menu-item {
            &>.menu-link {
                color: $white;
                font-size: 14px;
                font-weight: 700;
                line-height: 30px;
                text-transform: uppercase;
                padding: 0 12px;
                border: 1px solid transparent;
            }
            &.menu-item-group>.menu-link {
                padding-right: 10px;
            }
            &.menu-item-group>.menu-toggle {
                display: none;
            }
            &:hover,
            &.active {
                .menu-link {
                    border-color: $white;
                }
            }
            &>.menu-sub {
                top: calc(100% + 24px) !important;
                &::before {
                    height: 24px;
                }
            }
        }
        .menu-sub {
            border-radius: 0;
            background-color: rgba(#000, 0.6);
            .menu-link {
                color: $white;
                font-size: 12px;
                line-height: 28px;
            }
            .menu-toggle {
                color: $white;
                height: 28px;
            }
            .menu-item:hover>.menu-link {
                background-color: rgba(#fff, 0.2);
            }
        }
    }
}