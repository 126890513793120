.relate {
  background-color: #f5f4f4;
  padding: 24px 9px;

  &__list {
    padding-left: 17px;
    margin-bottom: 0;

    a {
      display: inline-block;
      color: $gray-800;
      line-height: 25px;
    }
  }
}

.post {
  &-sapo {
    font-size: 14px;
    font-weight: 700;
  }

  &-content {
    ul {
      list-style: none;
    }
  }
}
