.follow {
  background-color: rgba(#000, 0.3);
  color: $white;
  padding: 22px 0;

  &__inner {
    position: relative;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 2px;
  }

  &__form {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 475px;

    .form-control {
      border: 0;
      border-radius: 0;
    }

    .input-group-text {
      padding: 0 12px;
      text-transform: uppercase;
      background: $bg-gradient;
      border: 0;
      border-radius: 0;
      color: $white;
    }
  }

  @media (max-width: 1199px) {
    &__title {
      text-align: center;
    }

    &__desc {
      text-align: center;
    }

    &__form {
      max-width: 100%;
      position: static;
      transform: translateY(0);
      margin: 20px auto 0;
    }
  }
}

.footer {
  background: url('../images/footer-bg.jpg') center/cover no-repeat;

  &__grid {
    display: grid;
    grid-template-columns: 200px 1fr 335px;
    grid-gap: 50px;
    padding: 40px 0;
  }

  &__logo {
    display: inline-flex;
    max-width: 218px;
    margin-top: 60px;

    img {
      object-fit: contain;
      object-position: 0 0;
    }
  }

  @media (max-width: 1199px) {
    &__grid {
      grid-template-columns: 1fr;
      grid-gap: 30px;
    }

    &__logo {
      margin-top: 0;
    }
  }

  @media (max-width: 767px) {
    &__logo {
      img {
        height: 80px;
      }
    }
  }
}

.f-contact {
  color: $white;

  &__title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;

    span {
      display: inline-block;
      padding-bottom: 4px;
      border-bottom: 1px solid $white;
    }
  }

  &__list {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }

  &__list-item {
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    img {
      width: 21px;
      height: 21px;
      object-fit: contain;
      object-position: 50% 50%;
      margin-right: 6px;
    }

    a,
    a:hover {
      color: $white;
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 16px;
    }
  }
}

.f-fanpage {
  margin-bottom: 10px;

  &__title {
    font-size: 15px;
    font-weight: 700;
    color: $white;
    margin-bottom: 6px;
  }

  &__wrapper {
    max-width: 375px;
    overflow: hidden;
  }
}

.f-social {
  display: flex;
  list-style: none;
  margin-bottom: 8px;
  padding-left: 0;

  &__item {
    margin-right: 10px;
  }

  &__link {
    &:hover {
      text-decoration: none;
    }

    .fa {
      display: flex;
      width: 35px;
      height: 35px;
      justify-content: center;
      align-items: center;
      border: 1px solid $white;
      border-radius: 50%;
      color: $white;
      transition: 0.3s;
    }

    &:hover {
      .fa {
        border-color: transparent;
      }
      .fa-facebook {
        background-color: #37538d;
      }
      .fa-google-plus {
        background-color: #dd4b39;
      }
      .fa-youtube {
        background-color: #e73a35;
      }
    }
  }
}

.f-copyright {
  color: $white;
}
