.bg-gray {
  background-color: #f7f6f6;
}

.content-wrapper {
  background-color: $white;
  padding: 20px;
  position: relative;
  @include shadow-md;

  &-bg {
    background: url('../images/lines-bg.png') center/cover no-repeat, $white;
  }
}

.content-float {
  padding: 40px 0;
  position: absolute;
  top: 0;
  left: -70px;

  @media (max-width: 575px) {
    position: static;
    padding: 10px 0;
  }
}

.title {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;

  &--white {
    color: $white;
  }

  &--gradient {
    span {
      display: inline-block;
      background-image: $bg-gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    a {
      display: inline-block;
      position: relative;
      z-index: 1;
      background: linear-gradient(
        to right,
        #0bc4ff,
        #0113ff,
        #0bc4ff,
        #0113ff,
        #0bc4ff,
        #0113ff
      );
      background-size: 400%;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color: inherit;

      &:hover {
        text-decoration: none;
        animation: gradient-animate 8s linear infinite;
      }

      &:hover::before {
        content: '';
        display: block;
        position: absolute;
        width: 110%;
        height: 20%;
        transform: translateY(-50%);
        top: 50%;
        left: -10%;
        z-index: -1;
        border-radius: 4px;
        background: linear-gradient(
          to right,
          #0bc4ff,
          #0113ff,
          #0bc4ff,
          #0113ff,
          #0bc4ff,
          #0113ff
        );
        background-size: 400%;
        filter: blur(10px);
        animation: gradient-animate 8s linear infinite;
      }
    }
  }

  @media (max-width: 767px) {
    font-size: 20px;
  }
}

.button {
  @include btn-fill;
  display: inline-block;
  font-size: 16px;
  line-height: 29px;
  padding: 1px 15px 0;
  border: 1px solid $primary;
  color: $primary;
  background: 0;

  &:hover {
    color: $white;
    text-decoration: none;
  }

  &::before {
    background-color: $primary;
  }

  &--gradient {
    background: $bg-gradient;
    color: $white;
    border: 0;

    &::before {
      display: none;
    }
  }
}

@keyframes gradient-animate {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 400% 0;
  }
}
