@font-face {
  font-family: 'UTMAvo';
  src: url('../fonts/UTMAvo.eot');
  src: url('../fonts/UTMAvo.eot?#iefix') format('embedded-opentype'),
    url('../fonts/UTMAvo.woff') format('woff'),
    url('../fonts/UTMAvo.ttf') format('truetype'),
    url('../fonts/UTMAvo.svg#svgFontName') format('svg');
  font-weight: 400;
}

@font-face {
  font-family: 'UTMAvo';
  src: url('../fonts/UTMAvoBold.eot');
  src: url('../fonts/UTMAvoBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/UTMAvoBold.woff') format('woff'),
    url('../fonts/UTMAvoBold.ttf') format('truetype'),
    url('../fonts/UTMAvoBold.svg#svgFontName') format('svg');
  font-weight: 700;
}

@font-face {
  font-family: 'UTMAvo';
  src: url('../fonts/UTMAvo-Italic.eot');
  src: url('../fonts/UTMAvo-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/UTMAvo-Italic.woff') format('woff'),
    url('../fonts/UTMAvo-Italic.ttf') format('truetype'),
    url('../fonts/UTMAvo-Italic.svg#svgFontName') format('svg');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'UTMAvo';
  src: url('../fonts/UTMAvo-BoldItalic.eot');
  src: url('../fonts/UTMAvo-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/UTMAvo-BoldItalic.woff') format('woff'),
    url('../fonts/UTMAvo-BoldItalic.ttf') format('truetype'),
    url('../fonts/UTMAvo-BoldItalic.svg#svgFontName') format('svg');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'UTMAristote';
  src: url('../fonts/UTMAristote.eot');
  src: url('../fonts/UTMAristote.eot?#iefix') format('embedded-opentype'),
    url('../fonts/UTMAristote.woff') format('woff'),
    url('../fonts/UTMAristote.ttf') format('truetype'),
    url('../fonts/UTMAristote.svg#svgFontName') format('svg');
  font-weight: 400;
}

$aristore: 'UTMAristote', serif;
$avo: 'UTMAvo', sans-serif;
