.breadcrumb {
  padding: 8px 0;
  margin-bottom: 0;

  &-item {
    color: $white;

    & + &::before {
      color: $white;
    }

    &.active {
      color: $white;
    }

    a,
    a:hover {
      color: $white;
    }
  }
}
