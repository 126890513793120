.introduce {
  background: url('../images/introduce-bg.jpg') center/cover no-repeat;
  color: $white;
  padding: 210px 0 60px;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    background-color: rgba(#000, 0.4);
  }

  &__tabs {
    flex-direction: column;

    .nav-item {
      &:not(:last-child) {
        border-bottom: 1px solid $white;
      }
    }

    .nav-link {
      font-size: 16px;
      line-height: 30px;
      padding: 10px 0;
      color: $white;

      &:hover {
        color: $white;
        text-decoration: none;
      }

      &.active {
        font-size: 20px;
      }
    }
  }

  &__title {
    font-size: 30px;
    margin-bottom: 20px;
  }

  @media (min-width: 1200px) {
    .tab-content {
      min-height: calc(100vh - 731px);
    }
  }

  @media (max-width: 991px) {
    &__tabs {
      flex-direction: row;
      justify-content: center;

      .nav-item {
        margin: 0 10px;

        &:not(:last-child) {
          border-bottom: 0;
        }
      }

      .nav-link {
        &.active {
          border-bottom: 1px solid $white;
        }
      }
    }
  }

  @media (max-width: 767px) {
    &__tabs {
      display: block;
      text-align: center;
      white-space: nowrap;
      overflow: auto;

      .nav-item {
        display: inline-block;
        margin: 0 8px;
      }
    }
  }
}
