.banner {
    position: relative;
    z-index: 1;
    &__bg {
        display: block;
        width: 100%;
    }
    &__title {
        font-size: 25px;
        font-weight: 700;
        color: $white;
        text-align: center;
        text-transform: uppercase;
    }
    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding-top: 140px;
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
    }
    @media (max-width: 1199px) {
        padding-top: 82px;
        &__wrapper {
            padding-top: 82px;
        }
    }
    @media (max-width: 575px) {
        &__wrapper {
            position: static;
            padding: 16px 0;
        }
        &__title {
            color: #333;
        }
        .breadcrumb {
            .breadcrumb-item.active,
            .breadcrumb-item a,
            .breadcrumb-item+.breadcrumb-item::before {
                color: #333;
            }
        }
    }
}