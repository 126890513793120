.pagination {
    margin-bottom: 0;
}

.page-link {
    border-radius: 0.25rem !important;
    color: $gray-800;
    padding: 8px 16px;
}

.page-item {
    margin-right: 5px;
    &:last-child {
        margin-right: 0;
    }
}

@media (max-width: 767px) {
    .page-link {
        padding: 4px 8px;
    }
}