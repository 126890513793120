.contact {
  &__title {
    border-bottom: 1px solid $border-color;
    margin-bottom: 15px;
    font-size: 25px;
    padding-bottom: 8px;
  }

  &__map {
    height: 370px;
    flex-grow: 1;
  }

  .form-control {
    border-radius: 0;
  }

  &__card {
    height: 100%;
    border: 10px solid $white;
    background: linear-gradient(90deg, #0bc4ff, #0113ff);
    padding: 24px 10px 12px;
    color: $white;
    border-radius: 20px;
    @include shadow;
    transition: 0.3s;

    &:hover {
      transform: scale(1.05);
    }
  }

  &__card-content {
    text-align: center;
  }

  &__card-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    margin-bottom: 10px;

    img {
      max-height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }
}
