.gallery-tabs {
  padding: 0 25px;

  .nav-item {
    flex: 1;
    margin: 0 25px;
  }

  .nav-link {
    background-color: #ebebeb;
    color: $gray-800;
    text-align: center;
    font-size: 18px;
    line-height: 30px;
    padding: 10px;
    transition: 0.3s;

    &:hover {
      background-color: #ccc;
    }

    &.active {
      background: $bg-gradient;
      color: $white;
      font-weight: 700;
    }
  }

  @media (max-width: 1199px) {
    padding: 0;

    .nav-item {
      margin: 0 2px;
    }

    .nav-link {
      font-size: 14px;
      padding: 6px;
    }
  }

  @media (max-width: 992px) {
    display: block;
    text-align: center;
    white-space: nowrap;
    overflow: auto;

    .nav-item {
      display: inline-block;
    }

    .nav-link {
      min-width: 160px;
    }
  }
}

.gallery-page {
  padding: 16% 0;
  background: url('../images/gallery-bg.png') center/cover no-repeat;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: row dense;
  grid-gap: 20px;
  width: 70%;
  margin: 0 auto;
  transform: rotate(45deg);

  &__item {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 100%;
    }

    &:nth-child(3) {
      grid-column-start: 2;
    }

    &:nth-child(4) {
      grid-column-start: 1;
    }
  }

  &__thumb {
    @include shadow-md;
    border-radius: 24px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 10px solid $white;
    overflow: hidden;
  }

  &__img {
    width: 150%;
    height: 150%;
    max-width: unset;
    object-fit: cover;
    object-position: 50% 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &__overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 10px solid #0569ff;
    background-color: $white;
    opacity: 0;
    transition: 0.4s;
  }

  &__thumb:hover &__overlay {
    opacity: 0.5;
    top: 50px;
    left: 50px;
    right: 50px;
    bottom: 50px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50px;
    left: 50px;
    right: 50px;
    bottom: 50px;
    transform: rotate(-45deg);
    opacity: 0;
    transition: 0.4s;
  }

  &__thumb:hover &__content {
    opacity: 1;
  }

  &__text {
    margin-bottom: 10px;
  }

  @media (max-width: 1400px) {
    width: 60%;

    &__thumb {
      border-width: 6px;
    }

    &__overlay {
      border-width: 6px;
    }

    &__text {
      font-size: 16px;
    }

    &__btn {
      font-size: 14px;
    }

    &__content {
      top: 30px;
      left: 30px;
      right: 30px;
      bottom: 30px;
    }

    &__thumb:hover &__overlay {
      opacity: 0.5;
      top: 30px;
      left: 30px;
      right: 30px;
      bottom: 30px;
    }
  }

  @media (max-width: 1199px) {
    width: 70%;

    &__content {
      top: 20px;
      left: 20px;
      right: 20px;
      bottom: 20px;
    }

    &__overlay {
      opacity: 0.5;
      top: 20px;
      right: 20px;
      bottom: 20px;
      left: 20px;
    }

    &__text {
      left: 20px;
      right: 20px;
    }

    &__content {
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    width: 78%;
    grid-gap: 8px;

    &__thumb {
      border-width: 2px;
      border-radius: 10px;
    }

    &__overlay {
      border-width: 2px;
    }

    &__content {
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
    }

    &__thumb:hover &__overlay {
      top: 15px;
      right: 15px;
      bottom: 15px;
      left: 15px;
    }

    &__text {
      font-size: 12px;
      margin-bottom: 8px;
    }

    &__btn {
      font-size: 12px;
      line-height: 24px;
    }
  }

  @media (max-width: 575px) {
    width: 60%;
    grid-template-columns: 1fr;
    grid-gap: 80px;
    transform: rotate(0);

    &__item {
      transform: rotate(45deg);
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 100%;
      }

      &:nth-child(3) {
        grid-column-start: 1;
      }

      &:nth-child(4) {
        grid-column-start: 1;
      }
    }
  }
}
