.news {
  @include shadow;
  display: flex;
  height: 100%;
  flex-direction: column;
  transition: 0.3s;

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }

  &__iwrap {
    @include iwrap(70%);
    @include img-shine;

    img {
      transition: 0.3s;
    }

    &:hover {
      img {
        transform: scale(1.5) rotate(-5deg);
      }
    }
  }

  &__body {
    padding: 20px;
    background-color: $white;
    flex-grow: 1;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  &__tag {
    display: inline-flex;
    font-size: 16px;
    color: #999;
    transition: 0.3s;

    &:hover {
      color: $primary;
    }
  }

  &__time {
    font-size: 14px;
    color: #999;
    font-style: italic;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
    line-height: 1.2;

    a {
      transition: 0.3s;
    }

    a:hover {
      color: $primary;
    }
  }

  @media (max-width: 1400px) {
    &__body {
      padding: 16px;
    }

    &__tag {
      font-size: 13px;
    }

    &__time {
      font-size: 13px;
    }

    &__title {
      font-size: 16px;
    }
  }
}

.news-2 {
  height: 100%;
  position: relative;
  z-index: 1;

  &__iwrap {
    height: 100%;
    @include iwrap(50%);
    @include hover-zoom;
  }

  &__title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: $white;
    background-color: rgba(#000, 0.3);
    padding: 10px 16px;
    margin-bottom: 0;
    font-size: 16px;
  }
}

.news-3 {
  &__iwrap {
    @include iwrap(56.25%);
    @include hover-zoom;
    width: 175px;
    margin-right: 10px;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
  }

  &__desc {
    @include max-line(2);
    color: #666;
  }

  @media (max-width: 575px) {
    &__iwrap {
      width: 140px;
    }

    &__title {
      @include max-line(2);
    }
  }
}

.news-4 {
  &__iwrap {
    @include iwrap(56.25%);
    @include hover-zoom;
    width: 320px;
    margin-right: 20px;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 12px;
    min-height: 42px;

    a:hover {
      color: $primary;
    }
  }

  &__desc {
    @include max-line(3);
    color: #666;
    margin-bottom: 16px;
  }

  @media (max-width: 767px) {
    &__iwrap {
      width: 200px;
    }
  }

  @media (max-width: 575px) {
    flex-direction: column;

    &__iwrap {
      width: 100%;
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
}

.frame {
  position: relative;
  z-index: 1;

  &__iwrap {
    @include iwrap(90%);

    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid $white;
      transition: 0.3s;
    }
  }

  &:hover &__iwrap::after {
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
  }

  &__title {
    display: block;
    position: absolute;
    top: 50%;
    left: 40px;
    right: 50px;
    transform: translateY(-50%);
    font-size: 16px;
    padding: 6px;
    text-align: center;
    background-color: rgba(#000, 0.8);
    color: $white;
    opacity: 0;
    margin-top: 20px;
    transition: 0.3s;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  &:hover &__title {
    opacity: 1;
    margin-top: 0;
  }
}

.video {
  display: flex;
  height: 100%;
  flex-direction: column;
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: none;
  }

  &__iwrap {
    display: block;
    width: 100%;
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 56.25%;
      transition: 0.3s;
      background-color: rgba(#000, 0.2);
    }
  }

  &:hover &__iwrap::before {
    background-color: rgba(#000, 0.5);
  }

  &__img {
    @include img-cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &__icon {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 30%;
  }

  &__title {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: #ebebeb;
    padding: 20px;
    font-size: 14px;
    margin-bottom: 0;
    transition: 0.5s;
    font-weight: 700;
  }

  &:hover &__title {
    background-color: $primary;
    color: $white;
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 14px;
      padding: 10px;
    }
  }
}

.educate {
  &__frame {
    padding: 24px 0 24px 24px;
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 24px;
      bottom: 0;
      background: $bg-gradient;
      z-index: -1;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 24px;
      bottom: 0;
      background: $white;
      z-index: -1;
      border: 2px solid transparent;
      background-clip: content-box;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__iwrap {
    @include iwrap(56.25%);
    @include img-shine;

    img {
      transition: 0.3s;
    }

    &:hover {
      img {
        transform: scale(1.5) rotate(-5deg);
      }
    }
  }

  &__body {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-self: stretch;
    padding: 24px 0;
  }

  &__title {
    font-size: 16px;
    text-align: left;
  }

  &__desc {
    margin-bottom: 16px;
    text-align: justify;
  }

  &--right &__frame {
    padding: 24px 24px 24px 0;

    &::before,
    &::after {
      left: 24px;
      right: 0;
    }
  }

  &__title-2 {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    &--right {
      .row {
        flex-direction: row-reverse;
      }
    }
  }
}
